/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect } from 'react';

import cx from 'clsx';
import * as PropTypes from 'prop-types';

import useVideoPlayer from '../../hooks/useVideoPlayer';
import Portal from '../Portal';

import './Testimonial.styles.scss';

const Testimonial = ({ videoSrc, text, author, backgroundSrc }) => {
  const videoElement = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const { play, stop, handleOnTimeUpdate } = useVideoPlayer(videoElement);

  const handlePlay = () => {
    document.body.classList.add('no-overflow');
    setScrollY(window.scrollY);
    setIsOpen(true);
    play();
  };

  const handleStop = () => {
    document.body.classList.remove('no-overflow');
    setIsOpen(false);
    stop();
    videoElement.current.currentTime = 0;
  };

  useEffect(() => {
    const handleEscapePress = (e) => {
      if (e.key === 'Escape') {
        handleStop();
      }
    };

    document.addEventListener('keydown', handleEscapePress);

    return () => document.removeEventListener('keydown', handleEscapePress);
  });

  const renderVideo = (
    <div
      className={cx('video', { 'video--open': isOpen })}
      style={{ top: scrollY }}
    >
      <video
        src={videoSrc}
        ref={videoElement}
        onTimeUpdate={handleOnTimeUpdate}
      />
      <button type="button" className="video__close" onClick={handleStop} />
    </div>
  );

  return (
    <>
      <img
        src={backgroundSrc}
        alt={`Depoimento ${author.name}`}
        width="236px"
        height="338px"
      />
      <button type="button" onClick={handlePlay} className="item__play">
        <img
          src="https://assets.eye.com.br/play-button.svg"
          alt="Botão de play"
          width="62px"
          height="62px"
        />
      </button>

      <div className="item__text">
        <span className="item__testimonial">{text}</span>
        <span className="item__author">
          <strong>{author.name}</strong>
          <br />
          {author.occupation}
        </span>
      </div>
      <Portal>{renderVideo}</Portal>
    </>
  );
};

Testimonial.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
  backgroundSrc: PropTypes.string.isRequired,
};

export default Testimonial;
