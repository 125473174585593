/* eslint-disable import/no-unresolved */
import React from 'react';

import './index.scss';
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import iconBox from '../../images/icon-box.svg';
import spreadSheet from '../../images/spreadsheet.svg';
import Advantage from './Advantage';

const advantages = [
  {
    id: 0,
    icon: 'https://assets.eye.com.br/system-pdv-1.svg',
    title: 'Catálogo de produtos',
    description:
      'Personalize e venda seus produtos,\n combos, kits e adicionais. ',
  },
  {
    id: 1,
    icon: 'https://assets.eye.com.br/system-pdv-2.svg',
    title: 'Formas de pagamento',
    description:
      'Venda seus produtos usando dinheiro,\n débito, crédito, parcelado, PIX, VA/VR.',
  },
  {
    id: 2,
    icon: iconBox,
    title: 'Controle de estoque',
    description:
      'Cadastre e controle a quantidade de\n itens dos seus produtos',
  },
  {
    id: 3,
    icon: 'https://assets.eye.com.br/system-pdv-3.svg',
    title: 'Relatórios de Vendas',
    description:
      'Monitore vendas diárias, produtos mais\n vendidos, performance de atendentes. ',
  },
  {
    id: 4,
    icon: spreadSheet,
    title: 'Controle de caixa',
    description:
      'Monitore os resultados das\n suas vendas por turno direto da\n máquina.',
  },
  {
    id: 5,
    icon: 'https://assets.eye.com.br/system-pdv-4.svg',
    title: 'Emissão de Notas fiscais',
    description:
      'Possibilidade de emissão de NFC-e\n CF-e SAT direto da sua maquininha.',
  },
  {
    id: 6,
    icon: 'https://assets.eye.com.br/system-pdv-6.svg',
    title: 'Integração com balanças',
    description:
      'Integração com balanças para\nlançamento de produtos por peso.',
  },
  {
    id: 7,
    icon: 'https://assets.eye.com.br/system-pdv-7.svg',
    title: 'Cadastro e fidelização',
    description:
      'Cadastro do perfil do seu cliente\npara identificação e fidelização.',
  },
  {
    id: 8,
    icon: 'https://assets.eye.com.br/system-pdv-8.svg',
    title: 'Descontos e taxas',
    description:
      'Liberdade para descontos aplicar\ndescontos ou taxa de serviço.',
  },
];

const Advantages = () => {
  const splitIntoGroups = (array, groupsTotal) => {
    const groups = [];
    const arrCopy = [...array];

    for (let x = groupsTotal; x > 0; x -= 1) {
      groups.push(arrCopy.splice(0, Math.ceil(arrCopy.length / x)));
    }

    return groups;
  };

  const renderSlidesGroups = () => {
    const groupItems = splitIntoGroups(advantages, 3);

    return groupItems.map((group, index) => {
      return (
        <SwiperSlide key={group[index].id}>{renderItems(group)}</SwiperSlide>
      );
    });
  };

  const renderGroups = (groupTotal = 3) => {
    const groupItems = splitIntoGroups(advantages, 3);

    return groupItems.map((group, index) => {
      return (
        <div className="advantage-item" key={group[index].id}>
          {renderItems(group)}
        </div>
      );
    });
  };

  const renderItems = (items) => {
    return items.map((item) => {
      return <Advantage advantage={item} key={item.id} />;
    });
  };

  return (
    <div className="advantages">
      <div className="advantages-container">
        <h2>Por que seu negócio precisa do sistema PDV Eye:</h2>
        <hr />

        <Swiper
          className="advantages-slider"
          modules={[Pagination, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          isVisible={false}
          pagination={{ clickable: true }}
          initialSlide={2}
          centeredSlides
          breakpoints={{
            // when window width is >= 480px
            480: {
              spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
              spaceBetween: 40,
            },
          }}
        >
          {renderSlidesGroups()}
        </Swiper>

        <div className="items-container">{renderGroups()}</div>
      </div>
    </div>
  );
};

export default Advantages;
