import React from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import arrowIcon from '../../images/arrow-purple-down.svg';
import './index.scss';

import supportWoman from '../../images/support-woman.png';

const Support = ({ buildCartLink }) => {
  const planToGo = `/carrinho${buildCartLink('P003')}`;

  return (
    <div className="support">
      <div className="container">
        <div className="grid-container">
          <div className="grid-item">
            <div className="img-container">
              <img src={supportWoman} alt="Suporte" />
            </div>
          </div>
          <div className="grid-item">
            <div className="support-info">
              <h2 className="support-heading">
                Sim, oferecemos o suporte que você precisa.
              </h2>
              <p className="support-subtext">
                Nossa equipe está disponível para o seu
                <br /> negócio -
                <span className="highlight"> já incluso na mensalidade!</span>
              </p>

              <div className="support-opening-hours">
                <h3 className="support-opening-hours-heading">
                  Horário de atendimentos:
                </h3>
                <div className="support-opening-hours-hours">
                  <div className="support-opening-hours-hours-hour">
                    <span className="days">Segunda a sábado</span>{' '}
                    <span className="range">09h às 22h</span>
                  </div>
                  <div className="support-opening-hours-hours-hour">
                    <span className="days">Domingos e feriados</span>
                    <span className="range">10h às 20h</span>
                  </div>
                </div>
                <p className="support-opening-hours-ways-of-contact">
                  Pelo painel de gestão
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Support.propTypes = {
  buildCartLink: PropTypes.func.isRequired,
};

export default Support;
