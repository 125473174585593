import React from 'react';

import * as PropTypes from 'prop-types';

import './PDVModal.styles.scss';

const PDVModal = ({ onClose }) => {
  return (
    <div className="pdv-modal">
      <div className="pdv-modal__modal scroll-container">
        <div className="pdv-modal__header">
          <button
            type="button"
            className="pdv-modal__headerClose"
            onClick={onClose}
          >
            <img src="https://assets.eye.com.br/close.svg" alt="close" />
          </button>
          <span className="pdv-modal__headerTitle pdv-modal__highlight">
            Confira todas as maquininhas que aceitam nosso sistema
          </span>
        </div>
        <div className="pdv-modal__container">
          <div className="pdv-modal__item">
            <h2>Getnet</h2>
            <div className="pdv-modal__imagesContainer">
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/getnet-pdv-logo-p2.png"
                    className="item__pdv"
                    alt="PDV Getnet 1"
                    width="77px"
                    height="172px"
                    loading="lazy"
                  />
                </div>
                <p>P2</p>
              </div>
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/getnet-pdv-logo-a8.png"
                    className="item__pdv"
                    alt="PDV Getnet 2"
                    width="63px"
                    height="135px"
                    loading="lazy"
                  />
                </div>
                <p>A8</p>
              </div>
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/getnet-pdv-logo-n910.png"
                    className="item__pdv"
                    alt="PDV Getnet 3"
                    width="63px"
                    height="135px"
                    loading="lazy"
                  />
                </div>
                <p>N910</p>
              </div>
            </div>
          </div>
          <div className="pdv-modal__item">
            <h2>Pagseguro</h2>
            <div className="pdv-modal__imagesContainer">
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/pagseguro-pdv-logo-a930-ess.png"
                    className="item__pdv"
                    alt="PDV Pagseguro"
                    width="83px"
                    height="135px"
                    loading="lazy"
                  />
                </div>
                <p>Moderninha Smart<br />(A930)</p>
              </div>
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/pagseguro-pdv-logo-p2-ess.png"
                    className="item__pdv"
                    alt="PDV Pagseguro 2"
                    width="87px"
                    height="158px"
                    loading="lazy"
                  />
                </div>
                <p>Moderninha Smart 2<br />(P2)</p>
              </div>
            </div>
          </div>
          <div className="pdv-modal__item">
            <h2>Stone</h2>
            <div className="pdv-modal__imagesContainer">
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/stone-pdv-logo-p2-ess.png"
                    className="item__pdv"
                    alt="PDV Stone 1"
                    width="54px"
                    height="139px"
                    loading="lazy"
                  />
                </div>
                <p>P2</p>
              </div>
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/stone-pdv-logo-a8-ess.png"
                    className="item__pdv"
                    alt="PDV Stone 2"
                    width="66px"
                    height="141px"
                    loading="lazy"
                  />
                </div>
                <p>A8</p>
              </div>
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/stone-pdv-logo-g700x-ess.png"
                    className="item__pdv"
                    alt="PDV Stone 3"
                    width="66px"
                    height="141px"
                    loading="lazy"
                  />
                </div>
                <p>GPOS700X</p>
              </div>
            </div>
          </div>
          <div className="pdv-modal__item">
            <h2>Cielo</h2>
            <div className="pdv-modal__imagesContainer">
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/cielo-pdv-logo-liov2-ess.png"
                    className="item__pdv"
                    alt="PDV Cielo"
                    width="50px"
                    height="141px"
                    loading="lazy"
                  />
                </div>
                <p>Lio V2</p>
              </div>
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/cielo-pdv-logo-liov3-ess.png"
                    className="item__pdv"
                    alt="PDV Cielo 2"
                    width="63px"
                    height="132px"
                    loading="lazy"
                  />
                </div>
                <p>Lio V3</p>
              </div>
            </div>
          </div>
          <div className="pdv-modal__item">
            <h2>Rede</h2>
            <div className="pdv-modal__imagesContainer">
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/rede-pdv-logo-g700-ess.png"
                    className="item__pdv"
                    alt="PDV Rede"
                    width="63px"
                    height="140px"
                    loading="lazy"
                  />
                </div>
                <p>GPOS700</p>
              </div>
              <div>
                <div className="pdv-modal__image">
                  <img
                    src="https://assets.eye.com.br/rede-pdv-logo-l400-ess.png"
                    className="item__pdv"
                    alt="PDV Rede 2"
                    width="125px"
                    height="165px"
                    loading="lazy"
                  />
                </div>
                <p>L400</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PDVModal.defaultProps = {};

PDVModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PDVModal;
