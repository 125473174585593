import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.onload = () => {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "46612917",
        formId: "50fc3a78-4358-44f8-a851-15e35fefa6ee",
        target: "#hubspot-form-container"
      });
    };
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//js.hsforms.net/forms/embed/v2.js"
          defer
        />
      </Helmet>
      <div
        id="hubspot-form-container"
      />
    </>
  );
};

export default HubSpotForm;