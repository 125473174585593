import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const Advantage = ({ advantage }) => {
  const { id, icon, title, description } = advantage;

  return (
    <div className={`advantage plan-${id}`}>
      {id === 5 && <div className="header">Vendido separadamente</div>}
      <div className="content">
        <div className="icon-container">
          <img src={icon} alt="bennefit" />
        </div>
        <div className="info">
          <h3 className="title">{title}</h3>
          <p className="description">{description}</p>
        </div>
      </div>
    </div>
  );
};

Advantage.propTypes = {
  advantage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Advantage;
