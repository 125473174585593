import React from 'react';
import './index.scss';

import currencyIcon from '../../images/currency-icon.svg';
import handCheckIcon from '../../images/hand-check-icon.svg';
import Item from './Item';

const noCostsList = [
  {
    id: 0,
    icon: handCheckIcon,
    title: 'Zero taxa de adesão',
    description:
      'Não cobramos taxa de adesão nem taxa\n implantação do sistema. Você paga a mensalidade\n e pronto, só começar a usar o sistema.',
  },
  {
    id: 1,
    icon: currencyIcon,
    title: 'Esqueça fidelidade contratual',
    description:
      'Aqui você não tem fidelidade contratual, isso é,\n você cancela quando quiser sem pagar nada\n a mais por isso.',
  },
];

const NoAdditionalCosts = () => {
  const renderList = () => {
    return noCostsList.map((listItem) => {
      return <Item item={listItem} key={listItem.id} />;
    });
  };

  return (
    <div className="no-aditional-costs curved-border">
      <div className="container">
        <h2>Sem gastos surpresa!</h2>
        <hr />
        <p className="sub-heading">
          Estrutura de investimento simples pra você focar em aumentar as vendas
          do seu negócio.
        </p>
        <div className="noCost-container">{renderList()}</div>
      </div>
    </div>
  );
};

export default NoAdditionalCosts;
