import React from 'react';
import './index.scss';

import PropTypes from 'prop-types';

const Item = ({ item }) => {
  const { icon, title, description } = item;

  return (
    <div className="no-additional-item">
      <div className="item-container">
        <img src={icon} alt={title} className="item-icon" />
      </div>
      <h3 className="item-title">{title}</h3>
      <p className="item-description">{description}</p>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default Item;
