/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import qs from 'qs';
import { Pagination, A11y, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQueryParam, StringParam } from 'use-query-params';

import Advantages from '../components/Advantages';
import Footer from '../components/Footer';
import NoAdditionalCosts from '../components/NoAdditionalCosts';
import PDVModal from '../components/PDVModal';
import Seo from '../components/Seo';
import Support from '../components/Support';
import Testimonial from '../components/Testimonial/Testimonial';
import { PORTAL_URL } from '../utils/constants';
import HubSpotForm from "../components/Forms/HubSpotForm";

import '../styles/index.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const testimonials = [
  <div className="carousel__container">
    <div className="carousel__item">
      <Testimonial
        backgroundSrc="https://assets.eye.com.br/testimonial-pedro-paiva.png"
        videoSrc="https://assets.eye.com.br/videos/20220707_CASE_KINTAL_VERTICAL_LANDING_1080p.mp4"
        text="“São 10, 15 segundos para fazer uma venda, e com a Eye reduzimos o espaço material e administrativo.”"
        author={{
          name: 'Pedro de Paiva',
          occupation: 'Kintal Agro - Floricultura',
        }}
      />
    </div>
  </div>,
  <div className="carousel__container">
    <div className="carousel__item">
      <Testimonial
        backgroundSrc="https://assets.eye.com.br/testimonial-thais-santos.png"
        videoSrc="https://assets.eye.com.br/videos/20220707_CASE_BULAH_VERTICAL_LANDING_1080p.mp4"
        text="“Eu consigo saber os produtos que saem no dia a dia e
        sei os horários de pico para ter mais atendentes.”"
        author={{
          name: 'Thais Santos',
          occupation: 'Bulah - Restaurante',
        }}
      />
    </div>
  </div>,
  <div className="carousel__container">
    <div className="carousel__item">
      <Testimonial
        backgroundSrc="https://assets.eye.com.br/testimonial-maisa-rodrigues.png"
        videoSrc="https://assets.eye.com.br/videos/20220707_CASE_MAISA_CABELEREIRA_VERTICAL_LANDING_1080p.mp4"
        text="“A Eye traz mais comodidade para o cliente e agilidade
        para o empreendedor.”"
        author={{
          name: 'Maisa Rodrigues',
          occupation: 'Hair Stylist - Salão de Beleza',
        }}
      />
    </div>
  </div>,
];

const segments = [
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/cafes.png"
        alt="Cafes"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/barbearias.png"
        alt="Barbearias"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/bares.png"
        alt="Bares"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/cosmeticos.png"
        alt="Bares"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/floriculturas.png"
        alt="Bares"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/lojas-de-presentes.png"
        alt="Lojas de Presentes"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/padarias.png"
        alt="Padarias"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/pet-shops.png"
        alt="Pet Shops"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/restaurantes.png"
        alt="Restaurantes"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
  <SwiperSlide>
    <div className="carousel__item">
      <img
        src="https://assets.eye.com.br/segments/saloes-de-beleza.png"
        alt="Salões de Beleza"
        width="469px"
        height="425px"
      />
    </div>
  </SwiperSlide>,
];

const IndexPage = () => {
  const [utmSource] = useQueryParam('utm_source', StringParam);
  const [utmMedium] = useQueryParam('utm_medium', StringParam);
  const [utmContent] = useQueryParam('utm_content', StringParam);
  const [utmCampaign] = useQueryParam('utm_campaign', StringParam);
  const [gClid] = useQueryParam('gclid', StringParam);
  const [fbClid] = useQueryParam('fbclid', StringParam);
  const [showPdvModal, setShowPdvModal] = useState(false);
  const formTimeout = useRef();

  const buildCartLink = (plan) => {
    const queryString = qs.stringify({
      plano: plan,
      utm_source: utmSource || undefined,
      utm_content: utmContent || undefined,
      utm_medium: utmMedium || undefined,
      utm_campaign: utmCampaign || undefined,
      gclid: gClid || undefined,
      fbclid: fbClid || undefined,
    });

    return queryString ? `?${queryString}` : '';
  };

  useEffect(() => {
    const fillInUtmsFields = setInterval(() => {
      const utmSourceField = document.querySelector('[name="cf_utm_source"]');
      const utmMediumField = document.querySelector('[name="cf_utm_medium"]');
      const utmContentField = document.querySelector('[name="cf_utm_content"]');
      const utmCampaignField = document.querySelector(
        '[name="cf_utm_campaign"]',
      );
      const gclidField = document.querySelector('[name="cf_gclid"]');
      const fbclidField = document.querySelector('[name="cf_fbclid"]');

      if (utmSourceField) utmSourceField.value = utmSource || '';
      if (utmMediumField) utmMediumField.value = utmMedium || '';
      if (utmContentField) utmContentField.value = utmContent || '';
      if (utmCampaignField) utmCampaignField.value = utmCampaign || '';
      if (gclidField) gclidField.value = gClid || '';
      if (fbclidField) fbclidField.value = fbClid || '';

      clearInterval(fillInUtmsFields);
    }, 3000);

    const changePhoneInputLabel = () => {
      const label = document.querySelector(
        'label[for="rd-phone_field-lthakfpq"]',
      );

      if (label && label.textContent) {
        label.textContent = 'Whatsapp';
      }
    };

    formTimeout.current = window.setTimeout(() => {
      changePhoneInputLabel();
    }, 1500);

    return () => {
      window.clearTimeout(formTimeout.current);
      clearInterval(fillInUtmsFields);
    };
  }, [fbClid, gClid, utmCampaign, utmContent, utmMedium, utmSource]);

  useEffect(() => {
    const handleCheckbox = (e) => {
      if (e.target.matches('.bricks-form__field__option__input')) {
        if (e.target.parentNode.classList.contains('checked')) {
          e.target.parentNode.classList.remove('checked');
        } else {
          e.target.parentNode.classList.add('checked');
        }
      }
    };

    document.addEventListener('click', handleCheckbox);

    return () => {
      document.removeEventListener('click', handleCheckbox);
      window.clearTimeout(formTimeout.current);
    };
  }, []);

  const handleShowPdvModal = () => setShowPdvModal((prev) => !prev);

  return (
    <>
      <Seo title="Home" />
      <header className="i-header">
        <nav className="i-header__nav">
          <ul>
            <li>
              <a
                className="eye-link"
                href={PORTAL_URL}
                target="_blank"
                rel="noreferrer"
              >
                Entrar
              </a>
            </li>
            <li>
              <AnchorLink
                className="eye-button is-desktop"
                title="Aproveitar Oferta"
                to="/#contato"
              >
                Fale com um especialista
              </AnchorLink>
            </li>
          </ul>
        </nav>
      </header>
      <main role="main" className="i-page">
        <section className="i-top is-mobile">
          <div className="i-top__left">
            <img
              src="https://assets.eye.com.br/logo.svg"
              width="94px"
              height="94px"
              alt="Logo Eye"
            />
            <h1>Venda seus produtos em segundos direto da maquininha.</h1>
            <p>
              Com o sistema Eye PDV, você vende seus produtos direto da sua
              maquininha, economiza e ainda acompanha todos os resultados das
              suas vendas em tempo real.
            </p>
            <AnchorLink className="eye-button" title="Eu Quero!" to="/#contato">
              Eu Quero!
            </AnchorLink>
          </div>
          <div className="i-top__right">
            <Swiper
              modules={[Autoplay, A11y, EffectFade]}
              spaceBetween={0}
              slidesPerView={1}
              initialSlide={2}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop
              centeredSlides
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
            >
              <SwiperSlide>
                <img
                  src="https://assets.eye.com.br/pos-bakery.png"
                  width="256px"
                  height="525px"
                  alt="Smart POS"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="https://assets.eye.com.br/pos-cosmetics.png"
                  width="256px"
                  height="525px"
                  alt="Smart POS"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="https://assets.eye.com.br/pos-flowers.png"
                  width="256px"
                  height="525px"
                  alt="Smart POS"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <section className="i-top--desktop is-desktop">
          <div className="container">
            <div className="i-top__left">
              <img
                src="https://assets.eye.com.br/logo-large.svg"
                width="244px"
                height="244px"
                alt="Logo Eye"
              />
            </div>
            <div className="i-top__middle">
              <img
                src="https://assets.eye.com.br/top-pdv-2.png"
                width="310px"
                height="633px"
                alt="Smart POS"
              />
              <video playsInline autoPlay muted loop>
                <source
                  src="https://assets.eye.com.br/top-pdv-video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="i-top__right">
              <h1>Venda seus produtos em segundos direto da maquininha.</h1>
              <p>
                Com o sistema Eye PDV, você vende seus produtos direto da sua
                maquininha, economiza e ainda acompanha todos os resultados das
                suas vendas em tempo real.
              </p>
              <AnchorLink
                className="eye-button"
                title="Eu Quero!"
                to="/#contato"
              >
                Eu Quero!
              </AnchorLink>
            </div>
          </div>
        </section>
        <section className="i-pdvsystem">
          <img
            src="https://assets.eye.com.br/caret-down.svg"
            alt="Ícone caret-down"
            width="28px"
            height="16px"
          />
          <h2>
            <strong>Como funciona</strong>
          </h2>
          <hr />
          <div className="i-pdvsystem__how-it-works-video">
            <iframe
              title="how-it-works"
              className="advertisement-video"
              src="https://www.youtube.com/embed/hOKm0Ml1y-g"
            />
          </div>
          <h2 className="advantages-title">
            Um sistema PDV <strong>feito para você</strong>
          </h2>
          <hr />
          <p className="is-desktop">
            Agilidade, facilidade e praticidade. Tudo que você precisa para dar
            o próximo passo no seu negócio.
          </p>
          <div className="i-pdvsystem__advantages">
            <div className="advantages__item">
              <img
                src="https://assets.eye.com.br/system-pdv-1.svg"
                className="item__image"
                alt="Carousel Item 1"
                width="57px"
                height="65px"
              />
              <div>
                <span className="item__heading">Ágil pra quem vende</span>
                <p className="item__text">
                  Venda de qualquer lugar, registrando e recebendo tudo pela sua
                  maquininha sem precisar de um computador.
                </p>
              </div>
            </div>
            <div className="advantages__item">
              <img
                src="https://assets.eye.com.br/system-pdv-2.svg"
                className="item__image"
                alt="Carousel Item 2"
                width="62px"
                height="56px"
              />
              <div>
                <span className="item__heading">Fácil pra quem compra</span>
                <p className="item__text">
                  Vendas em até 10seg. com várias formas de pagamento no seu
                  aplicativo PDV, como: PIX, cartão de crédito e até vale
                  refeição.
                </p>
              </div>
            </div>
            <div className="advantages__item">
              <img
                src="https://assets.eye.com.br/system-pdv-3.svg"
                className="item__image"
                alt="Carousel Item 3"
                width="68px"
                height="59px"
              />
              <div>
                <span className="item__heading">Prático pra quem gerencia</span>
                <p className="item__text">
                  Plataforma de gestão para acompanhamento em tempo real com
                  faturamento, produtos mais vendidos e muito mais.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="i-pdvcarousel">
          <h2>
            Funciona na <br />
            <strong>sua maquininha</strong>
          </h2>
          <hr />
          <p>
            Se você já tem uma <strong>POS Digital</strong> (a famosa maquininha
            smart), provavelmente você pode usar o Software{' '}
            <strong>Eye PDV</strong>. Estamos nas principais maquininhas do
            mercado, veja abaixo as mais comuns.
          </p>
          <div className="i-pdvcarousel__pdvs is-desktop">
            <div className="pdv__item">
              <img
                src="https://assets.eye.com.br/rede-pdv.png"
                className="item__pdv"
                alt="PDV Rede"
                width="166px"
                height="389px"
              />
              <img
                src="https://assets.eye.com.br/rede-logo.svg"
                className="item__logo"
                alt="Rede Logo"
                width="137px"
                height="45px"
              />
            </div>
            <div className="pdv__item">
              <img
                src="https://assets.eye.com.br/stone-pdv.png"
                className="item__pdv"
                alt="PDV Stone"
                width="219px"
                height="526px"
              />
              <img
                src="https://assets.eye.com.br/stone-logo.svg"
                className="item__logo"
                alt="Stone Logo"
                width="153px"
                height="57px"
              />
            </div>
            <div className="pdv__item getnet">
              <img
                src="https://assets.eye.com.br/getnet-pdv.png"
                className="item__pdv"
                alt="PDV GetNet"
                width="197px"
                height="401px"
              />
              <img
                src="https://assets.eye.com.br/getnet-logo.svg"
                className="item__logo"
                alt="GetNet Logo"
                width="170px"
                height="36px"
              />
            </div>
            <div className="pdv__item">
              <img
                src="https://assets.eye.com.br/cielo-pdv.png"
                className="item__pdv"
                alt="PDV Cielo"
                width="162px"
                height="439px"
              />
              <img
                src="https://assets.eye.com.br/cielo-logo.svg"
                className="item__logo"
                alt="Cielo Logo"
                width="116px"
                height="41px"
              />
            </div>
            <div className="pdv__item">
              <img
                src="https://assets.eye.com.br/pagseguro-pdv.png"
                className="item__pdv"
                alt="PDV Pagseguro"
                width="211px"
                height="384px"
              />
              <img
                src="https://assets.eye.com.br/pagseguro-logo.svg"
                className="item__logo"
                alt="Pagseguro Logo"
                width="177px"
                height="34px"
              />
            </div>
          </div>
          <div className="carousel is-mobile">
            <Swiper
              modules={[Autoplay, A11y]}
              spaceBetween={-160}
              slidesPerView={1}
              initialSlide={2}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop
              centeredSlides
              loopAdditionalSlides={1}
              breakpoints={{
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
            >
              <SwiperSlide>
                <div className="carousel__item">
                  <img
                    src="https://assets.eye.com.br/rede-pdv.png"
                    className="item__pdv"
                    alt="Carousel Item 1"
                    width="122px"
                    height="285px"
                  />
                  <img
                    src="https://assets.eye.com.br/rede-logo.svg"
                    className="item__logo"
                    alt="Carousel Item 1"
                    width="100px"
                    height="32px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="carousel__item">
                  <img
                    src="https://assets.eye.com.br/stone-pdv.png"
                    className="item__pdv"
                    alt="Carousel Item 1"
                    width="160px"
                    height="345px"
                  />
                  <img
                    src="https://assets.eye.com.br/stone-logo.svg"
                    className="item__logo"
                    alt="Carousel Item 1"
                    width="112px"
                    height="41px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="carousel__item">
                  <img
                    src="https://assets.eye.com.br/getnet-pdv.png"
                    className="item__pdv"
                    alt="Carousel Item 1"
                    width="143px"
                    height="291px"
                  />
                  <img
                    src="https://assets.eye.com.br/getnet-logo.svg"
                    className="item__logo"
                    alt="Carousel Item 1"
                    width="123px"
                    height="26px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="carousel__item">
                  <img
                    src="https://assets.eye.com.br/cielo-pdv.png"
                    className="item__pdv"
                    alt="Carousel Item 1"
                    width="119px"
                    height="322px"
                  />
                  <img
                    src="https://assets.eye.com.br/cielo-logo.svg"
                    className="item__logo"
                    alt="Carousel Item 1"
                    width="85px"
                    height="30px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="carousel__item">
                  <img
                    src="https://assets.eye.com.br/pagseguro-pdv.png"
                    className="item__pdv"
                    alt="Carousel Item 1"
                    width="154px"
                    height="281px"
                  />
                  <img
                    src="https://assets.eye.com.br/pagseguro-logo.svg"
                    className="item__logo"
                    alt="Carousel Item 1"
                    width="130px"
                    height="25px"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <button
            id="pdvs_availables"
            onClick={handleShowPdvModal}
            type="button"
            className="pdv__button"
          >
            Veja os modelos aceitos
          </button>
        </section>

        <section id="vantagens" className="i-advantages-section">
          <Advantages />
        </section>

        <section className="i-business-carousel">
          <div className="carousel">
            <Swiper
              modules={[Autoplay, A11y, EffectFade]}
              spaceBetween={0}
              slidesPerView={1}
              initialSlide={2}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop
              centeredSlides
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
            >
              {segments}
            </Swiper>
          </div>
          <div className="container">
            <h2>
              Se você quer vender,
              <br />
              <strong>conte com a gente.</strong>
            </h2>
            <p>
              Bares, floriculturas, barbearias e o que você imaginar. Atendemos
              todos os segmentos do varejo, comércio e serviços, e amamos os
              pequenos negócios.
            </p>
          </div>
        </section>
        <section className="no-costs">
          <NoAdditionalCosts />
        </section>
        <section className="support-section">
          <Support buildCartLink={buildCartLink} />
        </section>

        <section className="i-intersection-about-testimonials">
          <section className="i-about">
            <div className="container">
              <div className="container__text">
                <h2>Um sistema completo para você gerenciar as suas vendas</h2>
                <p>
                  Imagine saber exatamente o que foi vendido em tempo real de
                  onde você estiver? Com o Sistema <strong>Eye PDV</strong> você
                  tem um painel de gestão completo com faturamento por formas de
                  pagamento, produtos, grupos de produtos mais vendidos e muito
                  mais.
                </p>
              </div>
              <div className="dummy is-desktop" />
            </div>
          </section>
          <section className="i-testimonials">
            <div className="container">
              <h2>
                O que dizem <strong>nossos clientes</strong>
              </h2>
              <hr />
              <p>
                Veja abaixo o que alguns de nossos clientes tem a dizer sobre o
                PDV Móvel Eye.
              </p>
            </div>
            <div className="i-testimonials__testimonials is-desktop">
              {testimonials}
            </div>
            <div className="carousel is-mobile">
              <Swiper
                modules={[Pagination, A11y]}
                spaceBetween={-55}
                slidesPerView={1}
                pagination={{ clickable: true }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  // when window width is >= 480px
                  360: {
                    slidesPerView: 1,
                    spaceBetween: -55,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 160,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 260,
                  },
                }}
              >
                <SwiperSlide>{testimonials[0]}</SwiperSlide>
                <SwiperSlide>{testimonials[1]}</SwiperSlide>
                <SwiperSlide>{testimonials[2]}</SwiperSlide>
              </Swiper>
            </div>
          </section>
        </section>
        <section id="contato" className="i-contact-form">
          <div className="container">
            <h2>
              Fale com <strong>um especialista</strong>
            </h2>
            <hr />
            <p>
              Caso tenha alguma dúvida ou precise de um plano especial deixe
              seus dados que um especialista entrará em contato.
            </p>
          </div>
          <HubSpotForm />
          <div className="eye-container">
            <p className="bottom-hint">
              Ao solicitar um especialista, você concorda em receber
              comunicações da Eye relacionadas a nossas soluções e novidades
            </p>
          </div>
        </section>
      </main>
      <Footer />
      {showPdvModal && <PDVModal onClose={handleShowPdvModal} />}
    </>
  );
};

export default IndexPage;
